import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import NewsletterRegistrationSuccess from '../views/NewsletterRegistrationSuccess.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/privacy-policy',
		name: 'privacy_policy',
		component: PrivacyPolicy,
		meta: { scrollToTop: true }
	},
	{
		path: '/terms-and-conditions',
		name: 'terms_and_conditions',
		component: TermsAndConditions,
		meta: { scrollToTop: true }
	},
	{
		path: '/newsletter-registration-success',
		name: 'newsletter_registration_success',
		component: NewsletterRegistrationSuccess,
		meta: { scrollToTop: true }
	},
	{ 
		path: '/404', 
		component: NotFound
	},
	{
		path: '*', 
		redirect: '/404' 
	} 
]

const router = new VueRouter({
	mode: 'history',
	hash: false,
	// store,
	routes,
	scrollBehavior() {
		document.getElementById('header').classList.remove('inverted')
		document.getElementById('mobile_sticky_bottom').classList.add('show')
		document.getElementById('animated_mouse').classList.add('show')
		return { x: 0, y: 0 }
	}
})

export default router