<template>
	<div class="drawer-container">
		<label for="toggle_reservations" class="backdrop"></label>
		<div class="drawer">
			<div class="inner h-100">
				<div class="form-holder" v-if="success == false">
					<form class="reservations-form">
						<fieldset :class="step == 2 && 'd-none'">
							<div class="content">
								<div class="content--header">
									<div class="title">{{$t('reservations.step1_title')}}</div>
									<label for="toggle_reservations" class="close-btn"></label>
								</div>
								<div class="content--body">
									<div class="input-holder" id="for_nr_of_people">
										<div class="input-label">{{$t('reservations.input_labels.nr_of_people')}} *</div>
										<input class="text-input" type="number" min="1" max="100" v-model="form_data.nr_of_people" />
										<div class="error" id="error_for_nr_of_people"></div>
									</div>
									<div class="input-holder" id="for_date">
										<div class="input-label">{{$t('reservations.input_labels.pick_date')}} *</div>
										<Datepicker @selected="onPickDate" :inline="true" :monday-first="true" :language="lang" v-model="form_data.date" :disabled-dates="disabledDates"></Datepicker>
										<div class="error" id="error_for_date"></div>
									</div>
									<div class="input-holder radio-holder" id="for_time">
										<div class="input-label">{{$t('reservations.input_labels.time')}} *</div>
										<div class="time-radios">
											<label @click.stop="onPickTime($event)" class="time-radio" data-h="12" data-m="30">
												<input name="time" type="radio" v-model="form_data.time" value="12h30" hidden />
												<div class="radio">
													<span>12:30</span>
												</div>
											</label>
											<label @click.stop="onPickTime($event)" class="time-radio" data-h="13" data-m="30">
												<input name="time" type="radio" v-model="form_data.time" value="13h30" hidden />
												<div class="radio">
													<span>13:30</span>
												</div>
											</label>
											<label @click.stop="onPickTime($event)" class="time-radio" data-h="14" data-m="30">
												<input name="time" type="radio" v-model="form_data.time" value="14h30" hidden />
												<div class="radio">
													<span>14:30</span>
												</div>
											</label>
											<label @click.stop="onPickTime($event)" class="time-radio" data-h="19" data-m="30">
												<input name="time" type="radio" v-model="form_data.time" value="19h30" hidden />
												<div class="radio">
													<span>19:30</span>
												</div>
											</label>
											<label @click.stop="onPickTime($event)" class="time-radio" data-h="20" data-m="30">
												<input name="time" type="radio" v-model="form_data.time" value="20h30" hidden />
												<div class="radio">
													<span>20:30</span>
												</div>
											</label>
											<label @click.stop="onPickTime($event)" class="time-radio" data-h="21" data-m="30">
												<input name="time" type="radio" v-model="form_data.time" value="21h30" hidden />
												<div class="radio">
													<span>21:30</span>
												</div>
											</label>
										</div>
										<div class="error" id="error_for_time"></div>
									</div>
									<div class="input-holder radio-holder" id="for_table">
										<div class="input-label">{{$t('reservations.input_labels.table')}}</div>
										<div class="table-radios">
											<label>
												<input name="table" type="radio" v-model="form_data.table" value="Interior" hidden />
												<div class="radio">
													<span>{{$t('reservations.input_placeholders.table_indoors')}}</span>
												</div>
											</label>
											<label>
												<input name="table" type="radio" v-model="form_data.table" value="Exterior" hidden />
												<div class="radio">
													<span>{{$t('reservations.input_placeholders.table_outdoors')}}</span>
												</div>
											</label>
										</div>
										<div class="error"></div>
									</div>
									<div class="input-holder" id="for_message">
										<textarea class="text-input" :placeholder="$t('reservations.input_placeholders.message')" v-model="form_data.message"></textarea>
									</div>
								</div>
								<div class="content--footer">
									<button @click.prevent="requestFormStep2()" class="btn btn--primary w-100">
										{{$t('reservations.step1_button')}}
									</button>
								</div>
							</div>
						</fieldset>
						<fieldset :class="step == 1 && 'd-none'">
							<div class="content">
								<div class="content--header">
									<button class="back-btn" @click.prevent="requestFormStep1()"></button>
									<div class="title">{{$t('reservations.step2_title')}}</div>
									<label for="toggle_reservations" class="close-btn"></label>
								</div>
								<div class="content--body">
									<div class="input-holder" id="for_name">
										<div class="input-label">{{$t('reservations.input_labels.name')}} *</div>
										<input class="text-input" type="text" name="name" :placeholder="$t('reservations.input_placeholders.name')" v-model="form_data.name" />
										<div class="error" id="error_for_name"></div>
									</div>
									<div class="input-holder" id="for_phoneNumber">
										<div class="input-label">{{$t('reservations.input_labels.phone')}} *</div>
										<input class="text-input" type="text" name="phoneNumber" :placeholder="$t('reservations.input_placeholders.phone')" v-model="form_data.phoneNumber" />
										<div class="error" id="error_for_phoneNumber"></div>
									</div>
									<div class="input-holder" id="for_email">
										<div class="input-label">{{$t('reservations.input_labels.email')}} *</div>
										<input class="text-input" type="email" name="email" :placeholder="$t('reservations.input_placeholders.email')" v-model="form_data.email" />
										<div class="error" id="error_for_email"></div>
									</div>
									<div class="input-holder checkbox-holder">
										<label>
											<input type="checkbox" v-model="subscribe_newsletter" autocomplete="off" hidden />
											<div class="checkbox"></div>
											<span class="note">{{$t('reservations.input_labels.more_info')}}</span>
										</label>
										<div class="error"></div>
									</div>
									<div class="note" v-html="$t('reservations.step2_text')"></div>
								</div>
								<div class="content--footer">
									<!-- listen to verify event emited by the recaptcha component -->
									<button @click.prevent="requestFormSubmit()" type="submit" class="btn btn--primary w-100">
										<span>{{$t('reservations.step2_button')}}</span>	
										<svg version="1.1" class="spinner" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
											<path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
												<animateTransform attributeType="xml"
													attributeName="transform"
													type="rotate"
													from="0 25 25"
													to="360 25 25"
													dur="0.6s"
													repeatCount="indefinite"/>
											</path>
										</svg>
									</button>
									<recaptcha ref="recaptcha2" widgetId="2" @verify="submit"></recaptcha>
									<div class="note mt-3" v-html="$t('reservations.captcha')"></div>
									<div class="error" id="general_error"></div>
								</div>
							</div>
						</fieldset>
					</form>
				</div>
				<div class="success-holder h-100" v-if="success == true">
					<div class="content h-100 d-flex flex-column justify-content-center">
						<div class="content--header">
							<div class="title">{{$t('reservations.success_title')}}</div>
							<!-- <button @click.prevent="resetForm()" class="close-btn"></button> -->
						</div>
						<div class="content--body">
							<div class="txt text-center">
								<p class="text-uppercase">{{$t('reservations.success_subtitle')}}</p>
								<p>{{$t('reservations.success_text')}}</p>
							</div>
						</div>
						<div class="content--footer">
							<button @click.prevent="resetForm()" class="btn btn--primary w-100">{{$t('reservations.success_button')}}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import Language from 'vuejs-datepicker/src/locale/Language.js'
import Recaptcha from './Recaptcha'

export default {
	name: 'Drawer',
	data() {
		var customLangs = {
			pt: new Language(
				'Portuguese',
				['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
				['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
				['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa']
			),
			en: new Language(
				'English',
				['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
				['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
				['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
			)
		}
		return {
			widgetId: 0,
			customLangs: customLangs,
			lang: customLangs[localStorage.getItem('language') ? localStorage.getItem('language') : 'pt'],
			language: localStorage.getItem('language') ? localStorage.getItem('language') : 'pt',
			disabledDates: {
				to: new Date(Date.now() - 8640000)
			},
			step: 1,
			success: false,
			form_data: {
				nr_of_people: null,
				date: null,
				time: null,
				table: null,
				message: null,
				name: null,
				phoneNumber: null,
				email: null,
			},
			subscribe_newsletter: 0,
		}
	},
	updated() {
		if (this.language != localStorage.getItem('language')) {
			this.lang = this.customLangs[localStorage.getItem('language') ? localStorage.getItem('language') : 'pt']
			this.language = localStorage.getItem('language')
		}
	},
	components: {
		Datepicker,
		Recaptcha
	},
	watch: {
        step: function() {
			if (this.step == 1) {
				var that = this;
				setTimeout(function() {
					that.validateTimeRadios()
				},200)
			}
        }
    },
	methods: {
		// send your recaptcha token to the server to verify it
		submit (response) {
			this.submitForm(this.form_data,response)
		},
		// execute the recaptcha widget
		executeRecaptcha () {
			this.$refs.recaptcha2.execute()
		},


		requestFormStep1() {
			this.step--
		},
		requestFormStep2() {
			if (this.form_data.nr_of_people == null || this.form_data.nr_of_people < 1) {
				document.getElementById('for_nr_of_people').classList.add('has-error')
				if (localStorage.getItem('language') == 'pt') {
					document.getElementById('error_for_nr_of_people').innerHTML = '<span>Por favor escolha um nº válido de pessoas.</span>'
				} else {
					document.getElementById('error_for_nr_of_people').innerHTML = '<span>Please enter a valid number.</span>'
				}
				document.getElementsByClassName('form-holder')[0].scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
				return;
			} else {
				document.getElementById('for_nr_of_people').classList.remove('has-error')
				document.getElementById('error_for_nr_of_people').innerHTML = ''
			}
			if (this.form_data.date == null || this.form_data.date == '') {
				document.getElementById('for_date').classList.add('has-error')
				if (localStorage.getItem('language') == 'pt') {
					document.getElementById('error_for_date').innerHTML = '<span>Por favor escolha uma data.</span>'
				} else {
					document.getElementById('error_for_date').innerHTML = '<span>Please pick a date.</span>'
				}
				return;
			} else {
				document.getElementById('for_date').classList.remove('has-error')
				document.getElementById('error_for_date').innerHTML = ''
			}
			if (this.form_data.time == null || this.form_data.time == '') {
				document.getElementById('for_time').classList.add('has-error')
				if (localStorage.getItem('language') == 'pt') {
					document.getElementById('error_for_time').innerHTML = '<span>Por favor escolha uma hora.</span>'
				} else {
					document.getElementById('error_for_time').innerHTML = '<span>Please pick a time.</span>'
				}
				return;
			} else {
				document.getElementById('for_time').classList.remove('has-error')
				document.getElementById('error_for_time').innerHTML = ''
			}
			this.step++
		},
		requestFormSubmit() {
			if (this.form_data.name == null || this.form_data.name == '') {
				document.getElementById('for_name').classList.add('has-error')
				if (localStorage.getItem('language') == 'pt') {
					document.getElementById('error_for_name').innerHTML = '<span>Por favor insira um nome.</span>'
				} else {
					document.getElementById('error_for_name').innerHTML = '<span>Please enter a name.</span>'
				}
				return;
			} else {
				document.getElementById('for_name').classList.remove('has-error')
				document.getElementById('error_for_name').innerHTML = ''
			}
			if (this.form_data.phoneNumber == null || this.form_data.phoneNumber == '') {
				document.getElementById('for_phoneNumber').classList.add('has-error')
				if (localStorage.getItem('language') == 'pt') {
					document.getElementById('error_for_phoneNumber').innerHTML = '<span>Por favor insira um nº de telefone.</span>'
				} else {
					document.getElementById('error_for_phoneNumber').innerHTML = '<span>Please enter a phone number.</span>'
				}
				return;
			} else {
				document.getElementById('for_phoneNumber').classList.remove('has-error')
				document.getElementById('error_for_phoneNumber').innerHTML = ''
			}
			if (this.form_data.email == null || this.form_data.email == '') {
				document.getElementById('for_email').classList.add('has-error')
				if (localStorage.getItem('language') == 'pt') {
					document.getElementById('error_for_email').innerHTML = '<span>Por favor insira um email.</span>'
				} else {
					document.getElementById('error_for_email').innerHTML = '<span>Please enter an email.</span>'
				}
				return;
			} else {
				if (this.mailValidation(this.form_data.email) == false) {
					document.getElementById('for_email').classList.add('has-error')
					if (localStorage.getItem('language') == 'pt') {
						document.getElementById('error_for_email').innerHTML = '<span>Por favor insira um email válido.</span>'
					} else {
						document.getElementById('error_for_email').innerHTML = '<span>Please enter a valid email.</span>'
					}
					return;
				} else {
					document.getElementById('for_email').classList.remove('has-error')
					document.getElementById('error_for_email').innerHTML = ''
				}
			}
			this.executeRecaptcha();
		},
		submitForm(data,g_recaptcha_response) {
			var formData = new FormData();
			var form_data = { ...data };
			if (form_data.date) {
				form_data.date = new Date(form_data.date).toLocaleDateString('en-GB').replaceAll('/','-');
			}
			form_data = {
				'Data': form_data.date,
				'Hora': form_data.time,
				'E-mail': form_data.email,
				'Observações': form_data.message,
				'Nome': form_data.name,
				'Nº de pessoas': form_data.nr_of_people,
				'Contacto': form_data.phoneNumber,
				'Mesa': form_data.table,
			}
			formData.append('g-recaptcha-response', g_recaptcha_response);
			formData.append('guid', '4c512be3-5910-4f5e-9177-71e305bc3722');
			formData.append('subscribe_newsletter', this.subscribe_newsletter);
			formData.append('name', data.name);
			formData.append('email', data.email);
			formData.append('form_data',JSON.stringify(form_data))
			formData.append('lang',this.language ? this.language : 'pt')
			
			const requestOptions = {
				method: 'POST',
				body: formData
			}
			document.getElementsByClassName('reservations-form')[0].classList.add('loading')	
			let that = this;
			// let url = 'https://contactmanagement-osstaging.4itfuture.com/api/v1/contact_request' // development
			let url = 'https://contactmanagement.4itfuture.com/api/v1/contact_request' //prod
			fetch(url, requestOptions)
				.then(this.handleErrors)
				.then(function() {
					document.getElementsByClassName('reservations-form')[0].classList.remove('loading')
					that.success = true
				})
				.catch(function(res) {
					console.log('error:',res);
				});
		},
		onPickDate(date) {
			// reset time radios on pick date
			let that = this;
			const labels = document.getElementsByClassName('time-radio');
			labels.forEach(function (el) {
				el.querySelector('input').checked = false
				that.form_data.time = null
			})
			
			document.getElementById('for_time').classList.remove('has-error')
			document.getElementById('error_for_date').innerHTML = ''

			this.validateTimeRadios(date)
		},
		validateTimeRadios(date) {
			let now = new Date(),
				now_h = now.getHours(),
				now_m = now.getMinutes(),
				picked_date = date ? date : this.form_data.date;
			const labels = document.getElementsByClassName('time-radio');
			
			if (picked_date == null) {
				return;
			}

			// disable past time radios
			if (picked_date.toLocaleDateString('en-gb') == now.toLocaleDateString('en-gb')) {
				labels.forEach(function (el) {
					const label_h = parseInt(el.getAttribute('data-h'))
					const label_m = parseInt(el.getAttribute('data-m'))
					if (now_h >= label_h) {
						if (now_m >= label_m) {
							el.classList.add('disabled')
						} else {
							el.classList.remove('disabled')
						}
					} else {
						el.classList.remove('disabled')
					}
				});
			} else {
				labels.forEach(function (el) {
					el.classList.remove('disabled')
				})
			}
		},
		onPickTime(e) {
			// pick date first
			if (this.form_data.date) {				
				document.getElementById('for_time').classList.remove('has-error')
				document.getElementById('error_for_time').innerHTML = ''
			} else {
				e.target.checked = false
				document.getElementById('for_time').classList.add('has-error')
				if (localStorage.getItem('language') == 'pt') {
					document.getElementById('error_for_time').innerHTML = '<span>Por favor indique uma data primeiro.</span>'
				} else {
					document.getElementById('error_for_time').innerHTML = '<span>Please pick a date first.</span>'
				}
			}
		},
		resetForm() {
			document.getElementById('toggle_reservations').checked = false
			setTimeout(function () { 
				this.step = 1
				this.success = false
				this.form_data = {
					nr_of_people: null,
					date: null,
					time: null,
					table: null,
					message: null,
					name: null,
					phone: null,
					email: null,
				}
				this.subscribe_newsletter = 0
			}.bind(this), 500)
		},
		mailValidation(email) {
			let user = email.substring(0, email.indexOf('@'));
			let domain = email.substring(
				email.indexOf('@') + 1,
				email.length
			);
			if (
				user.length >= 1 &&
				domain.length >= 3 &&
				user.search('@') == -1 &&
				domain.search('@') == -1 &&
				user.search(' ') == -1 &&
				domain.search(' ') == -1 &&
				domain.search('.') != -1 &&
				domain.indexOf('.') >= 1 &&
				domain.lastIndexOf('.') < domain.length - 1
			) 
			{
				return true;
			} else {
				return false;
			}
		},
		handleErrors(response) {
			if (!response.ok) {
				document.getElementById('general_error').innerHTML = '<span>Ocorreu um erro inesperado, por favor tente mais tarde.</span>'
				document.getElementsByClassName('reservations-form')[0].classList.remove('loading')
				throw Error(response.statusText);
			}
			return response;
		},
		
	}
};
</script>
<style lang='scss'>
	.drawer-container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 10;
		pointer-events: none;
	}
	.drawer {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100vh;
		max-width: 400px;
		background-color: #fff;
		pointer-events: auto;
		z-index: 12;

		transition: opacity .2s ease-out, transform .2s ease-out, visibility .2s ease-out .2s;
		
		opacity: 0;
		visibility: hidden;
		transform: translateX(100%);
		.content {
			padding: 28px 60px 80px 60px;
		}
		.content--header {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			.close-btn {
				position: absolute;
				top: 7px;
				right: -30px;
				width: 44px;
				height: 44px;
				background: none;
				border: 0;
				outline: 0;
				cursor: pointer;
				&::after {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					width: 1px;
					height: 20px;
					transform: translate(-50%,-50%) rotate(45deg);
					background-color: #001A31;
				}
				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					width: 1px;
					height: 20px;
					transform: translate(-50%,-50%) rotate(135deg);
					background-color: #001A31;	
				}
			}
			.back-btn {
				position: absolute;
				top: 5px;
				left: 0;
				width: 44px;
				height: 44px;
				background-color: transparent;
				border: 0;
				padding: 0;
				cursor: pointer;
				&::after {
					content: '';
					display: inline-block;
					top: 0;
					left: 0;
					width: 10px;
					height: 10px;
					border-bottom: 1px solid #001A31;
					border-right: 1px solid #001A31;
					transform: rotate(-225deg);
				}
			}
			.title {
				font-size: 27px;
				line-height: 52px;
				text-align: center;
			}
		}
		.input-holder {
			.text-input {
				font-size: 11px;
			}
		}
	}
	.drawer-container .backdrop {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
        background-color: rgba(0,26,49,0.3);
		pointer-events: auto;
		z-index: 11;

		transition: opacity .2s ease-out, transform .2s ease-out, visibility .2s ease-out .2s;
		
		opacity: 0;
		visibility: hidden;
	}
	#toggle_reservations:checked ~ .drawer-container .drawer {
		transition-delay: 0s;
		opacity: 1;
		visibility: visible;
		transform: translate(0%);
	}
	#toggle_reservations:checked ~ .drawer-container .backdrop {
		transition-delay: 0s;
		opacity: 1;
		visibility: visible;
	}
	.input-label {
		font-size: 11px;
		text-transform: uppercase;
		margin-bottom: 6px;
	}
	.time-radios,
	.table-radios {
		label {
			cursor: pointer;
			transition: .2s ease-out;
			&:hover {
				transition-duration: 0s;
				background-color: #001A31;
				color: #fff;
			}
		}
	}

	.vdp-datepicker__calendar {
		border: 1px solid #001A31 !important;
		width: 100% !important;
	}
	.vdp-datepicker__calendar header {
		position: relative !important;
		display: block !important;
		height: 40px !important;
		border-bottom: 1px solid #001A31 !important;
		letter-spacing: 2px;
	}
	.vdp-datepicker__calendar header span,
	.vdp-datepicker__calendar .cell.day-header {
		font-size: 11px !important;
		text-transform: uppercase !important;
	}
	.vdp-datepicker__calendar .cell {
		font-size: 10px !important;
	}

	.vdp-datepicker__calendar .cell.selected {
		background: linear-gradient(90deg, rgba(0, 26, 49, 1) 95px , transparent 50%) no-repeat center center/25px 25px !important;
		color: #fff !important;
	}
	.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover, 
	.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover, 
	.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
		border: 1px solid transparent !important;
	}
	.vdp-datepicker__calendar header .prev:not(.disabled):hover, .vdp-datepicker__calendar header .next:not(.disabled):hover, .vdp-datepicker__calendar header .up:not(.disabled):hover {
		background: none !important;
	}
	.vdp-datepicker__calendar header {
		.prev,
		.next {
			&::after {
				margin-left: 0 !important;
				border: 0 !important;
				border-right: 1px solid #001A31 !important;
				border-bottom: 1px solid #001A31 !important;
				width: 10px !important;
				height: 10px !important;
				margin: 0 !important;
				display: inline-block !important;
			}
		}
		.prev {
			&::after {
				transform: translate(-50%,-50%) rotate(-225deg) !important;
			}
			&.disabled {
				opacity: .3;
			}	
		}
		.next {
			&::after {
				transform: translate(-50%,-50%) rotate(-45deg) !important;
			}	
		}
	}
	.time-radios {
		display: flex;
		flex-wrap: wrap;
		margin-left: -5px;
		margin-right: -5px;
		> label {
			width: calc(100% / 3 - 10px);
			margin: 5px;
			&.disabled {
				opacity: .3;
				pointer-events: none;
			}
		}
	}
	.table-radios {
		display: flex;
		flex-wrap: wrap;
		margin-left: -5px;
		margin-right: -5px;
		> label {
			width: calc(100% / 2 - 10px);
			margin: 5px;
		}
	}
	.form-holder {
		overflow: auto;
		height: 100%;
	}
	.drawer {
		.note {
			font-size: 10px;
			a {
				text-decoration: underline;
			}
		}
	}

	.drawer {
		.success-holder {
			.content--header .title {
				font-size: 36px;
				line-height: 40px;
				margin-bottom: 20px;
			}
			.content--body {
				.txt {
					padding-bottom: 20px;
					> p:nth-child(1) {
						letter-spacing: 2.4px;
						font-size: 14px;
					}
					> p:nth-child(2) {
						font-size: 16px;
						line-height: 19px;
					}
				}
			}
		}
	}

</style>