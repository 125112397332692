<template>
	<div id="mobile_sticky_bottom" class="mobile-sticky-bottom show">
        <div class="container-fluid">
            <div class="d-flex justify-content-between align-items-center">
                <a class="d-md-none menu-link" target="_blank" rel="noopener" href="https://booking.doleyapp.com/5bb079c5-6e88-4dfd-b97f-3246099ce6bf">{{$t('header.menu')}}</a>
                <div class="sn-wrapper d-md-none">
                    <a class="sn-holder" target="_blank" rel="noopener" href="https://www.thefork.pt/restaurante/inpar-r651073">
                        <img src="@/assets/img/sn_thefork.svg" width="22" height="22" alt="The Fork" />
                    </a>
                    <a class="sn-holder" target="_blank" rel="noopener" href="https://www.zomato.com/pt/grande-lisboa/restaurante-inpar-charneca-de-caparica">
                        <img src="@/assets/img/sn_zoomato.svg" width="22" height="22" alt="Zoomato" />
                    </a>
                    <!-- <a class="sn-holder" target="_blank" rel="noopener" href="https://www.tripadvisor.pt/Restaurant_Review-g1022768-d21397274-Reviews-Restaurante_Inpar-Almada_Setubal_District_Alentejo.html">
                        <img src="@/assets/img/sn_tripadvisor.svg" width="22" height="22" alt="TripAdvisor" />
                    </a> -->
                    <a class="sn-holder" target="_blank" rel="noopener" href="https://www.facebook.com/aroeiralisbonhotel/">
                        <img src="@/assets/img/zn_facebook.svg" width="22" height="22" alt="Facebook" />
                    </a>
                    <a class="sn-holder" target="_blank" rel="noopener" href="https://www.instagram.com/aroeiralisbonhotel/">
                        <img src="@/assets/img/sn_instagram.svg" width="22" height="22" alt="Instagram" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MobileStickyBottom'
};
</script>
<style lang="scss">
    .mobile-sticky-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 90px;
        display: flex;
        align-items: center;
        font-size: 12px;
        text-transform: uppercase;
        transition: opacity .2s ease-out, visibility .2s ease-out .2s;
        pointer-events: none;
        z-index: 2;

        opacity: 1;
        visibility: visible;
        a {
            pointer-events: auto;
        }
        .sn-wrapper {
            transform: translateX(10px);
        }
        .menu-link {
            letter-spacing: 1.5px;
        }
    }
    .fp-viewing-6 {
        .mobile-sticky-bottom {
            transition-delay: 0s;
            opacity: 0;
            visibility: hidden;
        }
    }
</style>