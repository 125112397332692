<template>
	<div 
		:id="'g_recaptcha'+widgetId"
		:data-sitekey="sitekey">
	</div>
</template>

<script>
export default {
	data () {
		return {
			// sitekey: '6LefzzwaAAAAAImp5W_kXzj_r0Y3qbE83Q6B6e6s' // development
			sitekey: '6Lcdb4QdAAAAAFu58mjGd8sgdD6FDQa4E6qt5jie' // prod
		}
	},
	props: ['widgetId'],
	methods: {
		renderRecaptcha () {
			if (!('grecaptcha' in window) || !('render' in window.grecaptcha)) {
				setTimeout(this.renderRecaptcha, 500);
				return;
			}
			if (this.widgetId == '1') {
				this.g_recaptcha1 = window.grecaptcha.render('g_recaptcha'+this.widgetId, {
					sitekey: this.sitekey,
					size: 'invisible',
					// the callback executed when the user solves the recaptcha
					callback: (response) => {
						// emit an event called verify with the response as payload
						this.$emit('verify', response)
						// reset the recaptcha widget so you can execute it again
						this.reset() 
					}
				})
			} else {
				this.g_recaptcha2 = window.grecaptcha.render('g_recaptcha'+this.widgetId, {
					sitekey: this.sitekey,
					size: 'invisible',
					// the callback executed when the user solves the recaptcha
					callback: (response) => {
						// emit an event called verify with the response as payload
						this.$emit('verify', response)
						// reset the recaptcha widget so you can execute it again
						this.reset() 
					}
				})
			}
			
		},
		execute () {
			if (this.widgetId == '1') {
				window.grecaptcha.execute(this.g_recaptcha1)
			} else {
				window.grecaptcha.execute(this.g_recaptcha2)
			}
		},
		reset () {
			if (this.widgetId == '1') {
				window.grecaptcha.reset(this.g_recaptcha1)
			} else {
				window.grecaptcha.reset(this.g_recaptcha2)
			}
		}
	},
	mounted () {
		this.renderRecaptcha()
	}
}
</script>