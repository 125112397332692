<template>
	<div id="app">
		<input id="toggle_reservations" type="checkbox" autocomplete="off" hidden />
		<Header />
		<transition name="fade" mode="out-in" @after-leave="afterLeave">
			<router-view :key="$route.path" />
		</transition>
		<Drawer />
		<CookieLaw :buttonText="$t('misc.yes')" :buttonDecline="true" :buttonDeclineText="$t('misc.no')" buttonDeclineClass="Cookie__button" transitionName="none" v-on:accept="onConsent()">
			<div slot="message">
				<div class="cookie--title">{{$t('misc.cookies')}}</div>
				<div class="cookie--content">
					<div>{{$t('misc.cookie_consent')}}</div>
					<router-link to="privacy-policy" :aria-label="$t('misc.privacy_policy')">{{$t('misc.more_info')}}</router-link>
				</div>
			</div>
		</CookieLaw>
		<div class="block-horizontal-mobile">
			<div class="wrapper">
				<div class="content">
					<p>{{$t('misc.go_vertical_msg')}}</p>
				</div>
			</div>
		</div>
		<!-- <div id="g_recaptcha" class="g-recaptcha" data-sitekey="6LefzzwaAAAAAImp5W_kXzj_r0Y3qbE83Q6B6e6s" data-callback="submitRecaptcha" data-size="invisible" style="position:fixed;top:50%;transform:translateY(-50%);left:0"></div> -->
	</div>
</template>

<script>
	// window.submitRecaptcha = function() {
	// 	return new Promise(function() {
	// 		var recaptcha_response = window.grecaptcha.getResponse();
	// 		console.log('submitRecaptcha', window, recaptcha_response)
	// 	});
	// }

	import CookieLaw from 'vue-cookie-law'
	import Header from '@/components/Header.vue'
	import Drawer from '@/components/Drawer.vue'

	export default {
		name: 'app',
		components: {
			CookieLaw,
			Header,
			Drawer,
		},
		data () {
			return {
			}
		},
		methods: {
			afterLeave() {
				
			},
			onConsent() {
				console.log('onConsent')
				window.dataLayer = window.dataLayer || [];
				function gtag(){window.dataLayer.push(arguments);}
				gtag('js', new Date());

				gtag('config', 'G-VV8KEWMHLL');
			}
		},
		mounted () {
			function WebpIsSupported(callback) {
				// If the browser doesn't has the method createImageBitmap, you can't display webp format
				if (!window.createImageBitmap) {
					callback(false);
					return;
				}
				// Base64 representation of a white point image
				var webpdata = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';
				// Retrieve the Image in Blob Format
				fetch(webpdata).then(function(response) {
					return response.blob();
				}).then(function(blob) {
					// If the createImageBitmap method succeeds, return true, otherwise false
					createImageBitmap(blob).then(function() {
						callback(true);
					}, function() {
						callback(false);
					});
				});
			}
			WebpIsSupported(function(isSupported){
				if (isSupported) {
					document.body.classList.remove('no-webp')
				} else {
					document.body.classList.add('no-webp')
				}
			});
		}
	}
</script>