<template>
	<div class="fullpage-holder">
		<full-page ref="fullpage" :options="options" id="fullpage">
			<div class="section">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-md-9 col-lg-6 offset-xl-1 col-xl-5 col-xxl-4">
							<div class="new-info-box box-xs-left box-md-left">
								<div class="content">
									<div class="pretitle" v-html="$t('slides.slide1.pretitle')"></div>
									<div class="title" v-html="$t('slides.slide1.title')"></div>
									<div class="txt" v-html="$t('slides.slide1.text')"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 offset-md-3 col-md-9 offset-lg-6 col-lg-6 offset-xl-6 col-xl-5 offset-xxl-7 col-xxl-4">
							<div class="new-info-box box-xs-left box-md-right">
								<div class="content">
									<div class="pretitle" v-html="$t('slides.slide2.pretitle')"></div>
									<div class="title" v-html="$t('slides.slide2.title')"></div>
									<div class="txt" v-html="$t('slides.slide2.text')"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-md-9 col-lg-6 offset-xl-6 col-xl-5 offset-xxl-7 col-xxl-4">
							<div class="new-info-box box-xs-left box-md-left">
								<div class="content">
									<div class="pretitle" v-html="$t('slides.slide3.pretitle')"></div>
									<div class="title" v-html="$t('slides.slide3.title')"></div>
									<div class="txt" v-html="$t('slides.slide3.text')"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-md-9 col-lg-6 offset-xl-1 col-xl-5 col-xxl-4">
							<div class="new-info-box box-xs-full box-md-left">
								<div class="content">
									<div class="pretitle" v-html="$t('slides.slide4.pretitle')"></div>
									<div class="title text-nowrap" v-html="$t('slides.slide4.title')"></div>
									<div class="txt" v-html="$t('slides.slide4.text')"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 offset-md-2 col-md-10 offset-lg-5 col-lg-7 offset-xl-6 col-xl-6">
							<VueSlickCarousel class="gallery" v-bind="gallery_slick_settings">
								<!-- <div class="img-holder">
									<picture class="responsive">
										<source srcset="@/assets/img/dishes/dish2-2x-min.webp" media="(min-width: 768px)" type="image/webp">
										<source srcset="@/assets/img/dishes/dish2-2x-min.jpg" media="(min-width: 768px)" type="image/jpeg">
										<source srcset="@/assets/img/dishes/dish2-min.webp" type="image/webp">
										<source srcset="@/assets/img/dishes/dish2-min.jpg" type="image/jpeg"> 
										<img src="@/assets/img/dishes/dish2-min.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div> -->
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0021_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0030_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0027_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0038_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0040_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0043_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0044_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0047_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0049_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0050_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0054_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0051_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0059_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0063_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0064_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0065_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0068_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0072_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0075_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0098_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0102_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0123_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0131_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0148_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0180_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0181_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0003_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0006_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0010_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0011_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
								<div class="img-holder">
									<picture class="responsive">
										<img src="@/assets/img/dishes/Nov_20_0014_01.jpg" alt="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort" loading="lazy">
									</picture>
								</div>
							</VueSlickCarousel>
						</div>
					</div>
				</div>				
			</div>
			<div class="section">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-md-9 offset-lg-6 col-lg-6 offset-xl-6 col-xl-5 offset-xxl-7 col-xxl-4">
							<div class="new-info-box box-xs-full box-md-left box-lg-right">
								<div class="content">
									<div class="pretitle" v-html="$t('slides.slide5.pretitle')"></div>
									<div class="title" v-html="$t('slides.slide5.title')"></div>
									<div class="txt" v-html="$t('slides.slide5.text')"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section">
				<div>
					<div class="container-fluid">
						<div class="title text-center mt-md-4" v-html="$t('slides.slide6.title')"></div>
					</div>
					<div class="container-fluid">
						<div class="row">
							<div class="col-12 offset-xl-1 col-xl-10">
								<VueSlickCarousel class="reviews" v-bind="reviews_slick_settings">
									<div class="review" v-for="(review, index) in $t('reviews')" :key="index">
										<div class="review-title" v-html="review.origin"></div>
										<div class="review-subtitle" v-html="review.date + ' | ' + review.rating"></div>
										<div class="txt" v-html="review.txt"></div>
									</div>
								</VueSlickCarousel>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section inverted">
				<div class="d-flex flex-column justify-content-between last-section">
					<div class="d-flex flex-column justify-content-center d-md-block" style="flex:1">
						<div class="container-fluid position-relative">
							<div class="row h-100 px-md-3 px-lg-0">
								<div class="col-12 col-lg-6 offset-lg-1 order-lg-2 col-xl-5">
									<div class="block">
										<div class="content">
											<div class="pretitle d-none d-lg-block visibility-hidden" v-html="$t('slides.slide7.block1.title')"></div>
											<div class="title newsletter-title" v-html="$t('slides.slide7.block1.title')"></div>

											<!-- <iframe v-if="lang == 'pt'" class="mj-w-res-iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://app.mailjet.com/widget/iframe/4526/Hiw" width="100%"></iframe>

											<iframe v-if="lang == 'en'" class="mj-w-res-iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://app.mailjet.com/widget/iframe/4526/HiB" width="100%"></iframe> -->

											<form class="newsletter-form">
												<div class="row">
													<div class="col-12 col-md-6">
														<div class="input-holder" id="for_newsletter_name">
															<input class="text-input" type="text" name="name" :aria-label="$t('slides.slide7.block1.input1')" :placeholder="$t('slides.slide7.block1.input1')" v-model="form_data.name" />
															<div class="error"></div>
														</div>
													</div>
													<div class="col-12 col-md-6">
														<div class="input-holder" id="for_newsletter_email">
															<input class="text-input" type="email" name="email" :aria-label="$t('slides.slide7.block1.input2')" :placeholder="$t('slides.slide7.block1.input2')" v-model="form_data.email" />
															<div class="error"></div>
														</div>
													</div>
													<div class="col-12 col-md-7 col-lg-10">
														<div class="input-holder checkbox-holder" id="for_newsletter_rgpd">
															<label>
																<input type="checkbox" v-model="form_data.rgpd" autocomplete="off" hidden />
																<div class="checkbox"></div>
																<span v-html="$t('slides.slide7.block1.input3')"></span>
															</label>
															<div class="error"></div>
														</div>
														<div class="input-holder checkbox-holder" id="for_newsletter_more_info">
															<label>
																<input type="checkbox" v-model="form_data.more_info" autocomplete="off" hidden />
																<div class="checkbox"></div>
																<span>{{$t('slides.slide7.block1.input4')}}</span>
															</label>
															<div class="error"></div>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-center justify-content-md-start mt-2 mb-3 mt-md-3 mb-md-4">
													<!-- listen to verify event emited by the recaptcha component -->
													<button @click.prevent="requestFormSubmit()" class="btn btn--primary inverted" type="submit">
														<span>{{$t('slides.slide7.block1.button')}}</span>
														<svg version="1.1" class="spinner" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
															<path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
																<animateTransform attributeType="xml"
																	attributeName="transform"
																	type="rotate"
																	from="0 25 25"
																	to="360 25 25"
																	dur="0.6s"
																	repeatCount="indefinite"/>
															</path>
														</svg>
													</button>
													<recaptcha ref="recaptcha" widgetId="1" @verify="submit"></recaptcha>
												</div>
											</form>
										</div>
									</div>
								</div>
								<div class="col-12 col-lg-5 order-lg-1 mt-md-5 mt-lg-0 offset-xl-1 col-xl-4 h-100 d-flex flex-column justify-content-between position-static">
									<div class="block pt-md-3 pt-lg-0">
										<div class="content">
											<div class="pretitle w-100 hide-on-short-mobiles">{{$t('slides.slide7.block2.pretitle')}}</div>
											<div class="title contact-title hide-on-short-mobiles">{{$t('slides.slide7.block2.title')}}</div>
											<div class="txt contacts">
												<div>Av. Pinhal da Aroeira, 2820-567</div>
												<div><a href="tel:+351 210 514 999">+351 210 514 999</a></div>
											</div>
											<div class="txt schedule mt-lg-5 mb-lg-5" v-html="$t('slides.slide7.block2.schedule')">
											</div>
										</div>
									</div>
									<div class="back-to-top d-none d-lg-inline-block" @click="$refs.fullpage.api.moveTo(1)">
										{{$t('misc.back_to_top')}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="footer">
						<div class="container-fluid">
							<div class="row">
								<div class="col-7 offset-xl-1 col-xl-5 d-md-flex align-items-end">
									<div class="legal">
										{{new Date().getFullYear()}} &copy; Inpar
										<br class="d-md-none" />
										<span class="d-none d-md-inline">&nbsp;-&nbsp;</span>
										<router-link to="/privacy-policy">
											<span>{{$t('misc.privacy_policy')}}</span>
										</router-link>
									</div>
								</div>
								<div class="col-5 col-xl-5 d-flex justify-content-end">
									<a href="https://aroeiralisbonhotel.com/" target="_blank" rel="noopener">
										<img src="@/assets/img/logo_aroeira_lisbon_hotel.svg" alt="Aroeira Lisbon Hotel Sea &amp; Gold Resort" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</full-page>
		<MobileStickyBottom />
		<AnimatedMouse />
		<div id="flash_msg" class="flash-msg"></div>
	</div>
</template>

<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import MobileStickyBottom from '@/components/MobileStickyBottom.vue'
	import AnimatedMouse from '@/components/AnimatedMouse.vue'
	import Recaptcha from '../components/Recaptcha'
	
	export default {
		name: 'home',
		data () {
			return {
				lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'pt',
				options: {
					licenseKey: '369B4D82-1B5646A8-99443769-5D4839B3',
					afterLoad: this.afterLoad,
					onLeave: this.onLeave,
					scrollOverflow: false,
					scrollBar: false,
					normalScrollElements: '.form-holder',
				},
				gallery_slick_settings: {
					dots: false,
					infinite: false,
					arrows: true,
					slidesToShow: 2.7,
					centerMode: false,
					responsive: [
						{
							breakpoint: 1199,
							settings: {
								infinite: false,
								arrows: true,
								slidesToShow: 2.5,
								centerMode: false,
							}
						},
						{
							breakpoint: 767,
							settings: {
								infinite: true,
								arrows: false,
								slidesToShow: 1,
								centerMode: true,
								// centerPadding: '5vh',
								centerPadding: '70px'
							}
						}
					]
				},
				reviews_slick_settings: {
					infinite: true,
					arrows: true,
					dots: false,
					centerMode: true,
					slidesToShow: 3,
					centerPadding: '0',
					adaptiveHeight: false,
					responsive: [
						{
							breakpoint: 1199,
							settings: {
								slidesToShow: 1,
								centerPadding: '200px',
								adaptiveHeight: false
							}
						},
						{
							breakpoint: 767,
							settings: {
								slidesToShow: 1,
								centerPadding: '0px',
								adaptiveHeight: true
							}
						}
					]
				},
				form_data: {
					name: null,
					email: null,
					rgpd: false,
					more_info: false
				},
				post_data: {
					name: null,
					email: null
				},
				newsletter_form_success: false
			}
		},
		updated() {
			if (this.lang != localStorage.getItem('language')) {
				this.lang = localStorage.getItem('language')
			}
		},
		components: {
			VueSlickCarousel,
			MobileStickyBottom,
			AnimatedMouse,
			Recaptcha
		},
		methods: {
			afterLoad (origin, destination) {
				if (document.querySelector('.last-section').offsetHeight + 100 + 60 >= window.innerHeight) {
					document.getElementsByClassName('hide-on-short-mobiles')[0].classList.add('d-none')
					document.getElementsByClassName('hide-on-short-mobiles')[1].classList.add('d-none')
				} else {
					document.getElementsByClassName('hide-on-short-mobiles')[0].classList.remove('d-none')
					document.getElementsByClassName('hide-on-short-mobiles')[1].classList.remove('d-none')
				}
				if (destination.isLast) {
					this.invert()
				} else {
					this.reverse()
				}
			},
			invert() {
				document.getElementById('header').classList.add('inverted')
				// document.getElementById('mobile_sticky_bottom').classList.remove('show')
				document.getElementById('animated_mouse').classList.remove('show')
			},
			reverse() {
				document.getElementById('header').classList.remove('inverted')
				// document.getElementById('mobile_sticky_bottom').classList.add('show')
				document.getElementById('animated_mouse').classList.add('show')
			},
			scrollToTop() {

			},
			requestFormSubmit() {
				// console.log('requestFormSubmit',this.form_data)
				if (this.form_data.name == null) {
					document.getElementById('for_newsletter_name').getElementsByClassName('error')[0].innerHTML = '<span>Por favor insira um nome.</span>'
					return;
				} else {
					document.getElementById('for_newsletter_name').getElementsByClassName('error')[0].innerHTML = ''
				}
				if (this.form_data.email == null) {
					document.getElementById('for_newsletter_email').getElementsByClassName('error')[0].innerHTML = '<span>Por favor insira um email válido.</span>'
					return;
				} else {
					if (this.mailValidation(this.form_data.email) == false) {
						document.getElementById('for_newsletter_email').getElementsByClassName('error')[0].innerHTML = '<span>Por favor insira um email válido.</span>'
						return;
					} else {
						document.getElementById('for_newsletter_email').getElementsByClassName('error')[0].innerHTML = ''
					}
				}
				if (this.form_data.rgpd == false) {
					document.getElementById('for_newsletter_rgpd').getElementsByClassName('error')[0].innerHTML = '<span>Este campo é obrigatório.</span>'
					return;
				} else {
					document.getElementById('for_newsletter_rgpd').getElementsByClassName('error')[0].innerHTML = ''
				}
				if (this.form_data.more_info == false) {
					document.getElementById('for_newsletter_more_info').getElementsByClassName('error')[0].innerHTML = '<span>Este campo é obrigatório.</span>'
					return;
				} else {
					document.getElementById('for_newsletter_more_info').getElementsByClassName('error')[0].innerHTML = ''
				}
				this.post_data.guid = '4c512be3-5910-4f5e-9177-71e305bc3722'
				this.post_data.name = this.form_data.name
				this.post_data.email = this.form_data.email
				this.executeRecaptcha();
			},
			executeRecaptcha () {
				this.$refs.recaptcha.execute()
			},
			submit (response) {
				// console.log('submit',response)
				this.submitForm(this.post_data,response)
			},
			submitForm(data,g_recaptcha_response) {
				data['g-recaptcha-response'] = g_recaptcha_response;
				data['lang'] = this.lang;
				var formData = new FormData();
				Object.entries(data).forEach(([key, value]) =>  formData.append(key, value));
				formData.append('lang', this.lang ? this.lang : 'pt')

				const requestOptions = {
					method: 'POST',
					redirect: 'follow',
					body: formData
				}
				document.getElementsByClassName('newsletter-form')[0].classList.add('loading')	
				let that = this;
				// let url = 'https://contactmanagement-osstaging.4itfuture.com/api/v1/newsletter_subscription' // development
				let url = 'https://contactmanagement.4itfuture.com/api/v1/newsletter_subscription' //prod
				fetch(url, requestOptions)
					.then(this.handleErrors)
					.then(function() {
						document.getElementsByClassName('newsletter-form')[0].classList.remove('loading')
						that.newsletter_form_success = true
						document.getElementById('flash_msg').innerHTML = 'Subscrição efectuada com sucesso!'
						document.getElementById('flash_msg').classList.add('success', 'show')
						setTimeout(function() {
							document.getElementById('flash_msg').classList.remove('success', 'show')
						},3000)
						setTimeout(function() {
							document.getElementById('flash_msg').innerHTML = ''
						},3500)
					})
					.catch(function(res) {
						console.log(res);
					});
			},
			mailValidation(email) {
				let user = email.substring(0, email.indexOf('@'));
				let domain = email.substring(
					email.indexOf('@') + 1,
					email.length
				);
				if (
					user.length >= 1 &&
					domain.length >= 3 &&
					user.search('@') == -1 &&
					domain.search('@') == -1 &&
					user.search(' ') == -1 &&
					domain.search(' ') == -1 &&
					domain.search('.') != -1 &&
					domain.indexOf('.') >= 1 &&
					domain.lastIndexOf('.') < domain.length - 1
				) 
				{
					return true;
				} else {
					return false;
				}
			},
			resetForm() {
				setTimeout(function () {
					this.form_data = {
						name: null,
						email: null,
						rgpd: false,
						more_info: false
					}
					this.post_data = {
						instanceId: null,
						name: null,
						email: null,
						phoneNumber: null,
						message: null
					}
				}.bind(this), 500)
			},
		}
	}
</script>
