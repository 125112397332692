<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8 my-5 py-5">
                <div class="pt-5">
                    <router-link to="/" class="back-arrow" aria-label="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort">
                        <div class="arrow"></div>
                        <span>{{$t('misc.back')}}</span>
                    </router-link>
                    <div class="title mb-4" v-html="$t('privacy_policy.title')"></div>
                    <!-- <div class="txt" v-html="$t('privacy_policy.body')"></div> -->
                    <div class="txt" v-if="$t('privacy_policy.lang') == 'en'">

                        <p>Last update April 10th / 2021</p>

                        <p>This privacy notice informs you about the information we collect when you use our site. By collecting this information, we are acting as a data controller and are required by law to provide you with information about us, why and how we use your data, and the rights you have in your data.</p>

                        <p>
                            <br>
                        </p>

                        <p>WHO WE ARE</p>

                        <p>We are the company Aroeira Lisbon Hotel – Unipessoal, Lda. Our address is Av. Pinhal da Aroeira, 1 – 2820-118 CHARNECA DA CAPARICA. You can contact us at the above address, by email at geral@aroeiralisbonhotel.com or by phone +351 210 514 999</p>

                        <p>You can contact our Data Protection Officer about any question related to our use of your personal data, questions should be addressed to geral@aroeiralisbonhotel.com, for the attention of the Data Protection Officer.</p>

                        <p>WHEN DO WE USE YOUR DATA?</p>

                        <p>• When using our website</p>

                        <p>• When submitting a form through our website</p>

                        <p>• When to sign up to receive our newsletter</p>

                        <p>• When to submit a comment about us</p>

                        <p>• Your rights as a Data Subject</p>

                        <p>• Right to claim</p>

                        <p>• Updates to this privacy policy</p>

                        <p>
                            <br>
                        </p>

                        <p>When using our website</p>

                        <p>When you use our website to search for products and services and view the information we make available, a number of cookies are used by us and third parties to enable the website to function, to gather useful information about visitors and to help make your most beneficial user experience.</p>

                        <p>Some of the cookies we use are strictly necessary for our website to work, these are:</p>

                        <p>Technical Cookies: We use technical cookies to display our website so that it works correctly, allowing us to provide you with an advanced and user-friendly website.</p>

                        <p>Functional Cookies: We also use these cookies to remember your preferences and help you use our website more efficiently.</p>

                        <p>Analytical Cookies: We use analytics cookies to help us understand how visitors engage with the site. It is possible to use a set of cookies to gather information and disseminate website usage statistics without personally identifying individual visitors. This means we can find out what works best to improve our site.</p>

                        <p>For more information about our use of cookies, see our cookie policy.</p>

                        <p>
                            <br>
                        </p>

                        <p>When submitting a form through our website</p>

                        <p>When you submit a form through our website, we ask for your name, telephone number and email address.</p>

                        <p>We use this information to respond to your inquiry, providing you with any requested information about our products and services. We may also send you email messages after you submit forms to gauge your interest and make sure we answer your question. We will do this based on our legitimate interest in providing accurate information prior to a sale.</p>

                        <p>Your form is stored and processed as an email on our computers within the European Economic Area (EEA).</p>

                        <p>We do not use the information you provide to make any automated decisions that could affect you.</p>

                        <p>We will keep the form email for two years, after that period they are archived and kept for seven years, after that period we delete them.</p>

                        <p>
                            <br>
                        </p>

                        <p>When to sign up to receive our newsletter</p>

                        <p>When you sign up to receive our newsletter, we ask for your name and email address.</p>

                        <p>We will ask for your consent to use your email address to send you our newsletter which contains information about our products and other information that may be of interest to you.</p>

                        <p>Your consent can be withdrawn at any time and we will stop sending you the newsletter.</p>

                        <p>We do not use the information you provide to make any automated decisions that could affect you.</p>

                        <p>We keep your personal data while we produce and distribute our newsletter. If you withdraw your consent, we will mark your data so that it is not used and delete it after two years.</p>

                        <p>When to submit a comment about us</p>

                        <p>When you submit a comment about us, we record your name, your email address and your IP (Internet Protocol) address, which is your computer's Internet address.</p>

                        <p>We use this information to provide independent, verified opinions about our products and services to potential customers. Your name and the content of your comment and your email address may be publicly displayed on our website. We will do this based on our legitimate interest in marketing our products and services.</p>

                        <p>We do not use the information you provide to make any automated decisions that could affect you.</p>

                        <p>Your personal data is stored as long as we are marketing the product or services to which the comment refers, after which it will be deleted. You can ask us to remove your comment at any time.</p>

                        <p>Your rights as a Data Subject</p>

                        <p>By law, you can ask us what information we collect about you, and you can ask us to correct it if it is inaccurate. We ask for your consent to the processing of your personal data, you can withdraw this consent at any time.</p>

                        <p>We may process your personal data because you have given us your consent or to fulfill a contract, you may ask us for a copy of the information in digital format so that you can transfer it to another provider.</p>

                        <p>We may process your personal data because you have given us your consent or legitimate interest, you may request that your data be deleted.</p>

                        <p>You have the right to ask us to suspend the processing of your personal data for a period of time if you believe that we are not doing so lawfully.</p>

                        <p>Finally, in some circumstances, you may ask us not to do automated processing or profiling.</p>

                        <p>To submit a request for your personal data you can do so by email, mail or telephone, see the contact information provided above under “About Us”</p>

                        <p>right of complaint</p>

                        <p>If you have a complaint about our handling of your personal data, we prefer that you contact us directly first so that we can resolve your complaint. However, it is also possible to contact the National Data Protection Commission through its website at https://www.cnpd.pt/ or to the address:</p>

                        <p>
                            <br>
                        </p>

                        <p>National Data Protection Commission</p>

                        <p>Rua de São Bento n.º 148-3º</p>

                        <p>1200-821 Lisbon</p>

                        <p>Updates to this privacy policy</p>

                        <p>We will regularly review this policy and, if necessary, update this privacy policy as our services and processing of personal data evolve. If we intend to use your personal data in a way that we have not previously identified, we will contact you to provide information about such use and, if necessary, ask for your consent.</p>

                        <p>We will update the version number and date of this document whenever it changes.</p>

                    </div>

                    <div class="txt" v-else>
                        <p>Última atualização 10 de Abril /2021&nbsp;</p>

                        <p>Este aviso de privacidade informa-o sobre as informações que recolhemos quando utiliza o nosso site. Ao recolher essa informação, estamos a atuar como um responsável pelo tratamento de dados e, por lei, somos obrigados a fornecer-lhe informações sobre nós, sobre o porquê e como utilizamos os seus dados, e sobre os direitos que tem sobre seus dados.</p>

                        <p>
                            <br>
                        </p>

                        <p>QUEM SOMOS&nbsp;</p>

                        <p>Somos a empresa Aroeira Lisbon Hotel – Unipessoal, Lda. &nbsp;O nosso endereço é Av. Pinhal da Aroeira, 1 – 2820-118 CHARNECA DA CAPARICA. Pode contatar-nos pelo endereço acima, por email em geral@aroeiralisbonhotel.com ou por telefone +351 &nbsp;210 514 999</p>

                        <p>Pode contactar o nosso Responsável Pela Proteção de Dados, sobre qualquer questão relacionada com o nosso uso dos seus dados pessoais, as questões devem ser endereçadas para geral@aroeiralisbonhotel.com, ao cuidado do Responsável pela Proteção de Dados.&nbsp;</p>

                        <p>
                            <br>
                        </p>

                        <p>QUANDO UTILIZAMOS OS SEUS DADOS?&nbsp;</p>

                        <p>• &nbsp; &nbsp;Ao utilizar o nosso Website</p>

                        <p>• &nbsp; &nbsp;Quando envia um formulário através do nosso site</p>

                        <p>• &nbsp; &nbsp;Quando se inscrever para receber a nossa newsletter</p>

                        <p>• &nbsp; &nbsp;Quando enviar um comentário sobre nós</p>

                        <p>• &nbsp; &nbsp;Os seus direitos como Titular de dados</p>

                        <p>• &nbsp; &nbsp;Direito de reclamação</p>

                        <p>• &nbsp; &nbsp;Atualizações a esta política de privacidade</p>

                        <p>
                            <br>
                        </p>

                        <p>Ao utilizar o nosso Website</p>

                        <p>Quando utiliza o nosso Web site para procurar, produtos e serviços e ver as informações que disponibilizamos, uma série de os cookies são usados por nós e por terceiros para permitir que o site funcione, para recolher informações úteis sobre os visitantes e para ajudar a tornar sua experiência de utilizador mais proveitosa.&nbsp;</p>

                        <p>Alguns dos cookies que usamos são estritamente necessários para o nosso site funcionar, estes são:</p>

                        <p>Cookies Técnicos: usamos cookies técnicos para mostrar o nosso site, para que este funcione corretamente, permitindo fornecer-lhe um site avançado e de fácil utilização.</p>

                        <p>Cookies Funcionais: também usamos estes cookies para relembrar as suas preferências e ajudá-lo a usar o nosso site de forma mais eficiente.</p>

                        <p>Cookies Analíticos: usamos cookies analíticos para nos ajudar a entender como os visitantes se envolvem com o site. É possível usar um conjunto de cookies para recolher informações e divulgar estatísticas de uso do site sem identificar pessoalmente visitantes individuais. Isto significa que podemos descobrir o que funciona melhor para melhorar o nosso site.</p>

                        <p>
                            <br>
                        </p>

                        <p>Para mais informações sobre o nosso uso de cookies, consulte a nossa política de cookies.&nbsp;</p>

                        <p>
                            <br>
                        </p>

                        <p>Quando envia um formulário através do nosso site</p>

                        <p>Quando envia um formulário através do nosso website, pedimos-lhe o seu nome, número de telefone e endereço de e-mail.</p>

                        <p>Utilizamos esta informação para responder à sua consulta, fornecendo-lhe qualquer informação solicitada sobre os nossos produtos e serviços. Também podemos enviar-lhe mensagens por correio eletrónico após o seu envio de formulários para avaliar o seu interesse e assegurarmo-nos de que respondemos à sua questão. Faremos isto baseado no nosso interesse legítimo em fornecer a informação exata antes duma venda.</p>

                        <p>O seu formulário é armazenado e processado como um e-mail nos nossos computadores no âmbito do espaço económico europeu (EEE).</p>

                        <p>Não usamos as informações que fornece para fazer quaisquer decisões automatizadas que possam afetá-lo.</p>

                        <p>Iremos manter o email do formulário por dois anos, após esse período são arquivados e mantidos por sete anos, após esse período apagamo-los.&nbsp;</p>

                        <p>
                            <br>
                        </p>

                        <p>Quando se inscrever para receber a nossa newsletter</p>

                        <p>Quando se inscrever para receber a nossa newsletter, pedimos o seu nome e endereço de e-mail.</p>

                        <p>Solicitaremos o seu consentimento para usar o endereço de e-mail para lhe enviarmos nossa newsletter que contém informações sobre os nossos produtos e outras informações que podem ser do seu interesse.</p>

                        <p>O seu consentimento pode ser retirado a qualquer momento e iremos deixar de enviar-lhe a newsletter.</p>

                        <p>Não usamos as informações que fornece para fazer quaisquer decisões automatizadas que possam afetá-lo.</p>

                        <p>Mantemos os seus dados pessoais enquanto produzirmos e distribuirmos a nossa newsletter. Se retirar o seu consentimento, vamos marcar os seus dados para que eles não sejam utilizados e apagá-los após dois anos.&nbsp;</p>

                        <p>Quando enviar um comentário sobre nós</p>

                        <p>Quando envia um comentário sobre nós gravamos seu nome, seu endereço de e-mail e seu IP (Internet Protocol) endereço, que é o endereço do seu computador na Internet.</p>

                        <p>Usamos esta informação para que se forneçam opiniões independentes verificadas sobre nossos produtos e serviços aos clientes potenciais. O seu nome e o conteúdo do seu comentário e o seu endereço de e-mail podem ser exibidos publicamente no nosso site. Faremos isso com base no nosso legítimo interesse em comercializar os nossos produtos e serviços.</p>

                        <p>Não usamos as informações que fornece para fazer quaisquer decisões automatizadas que possam afetá-lo.</p>

                        <p>Os seus dados pessoais são armazenados desde que estejamos a comercializar o produto ou serviços a que o comentário se refere, após o qual será apagado. Pode pedir-nos para remover o seu comentário a qualquer momento.</p>

                        <p>Os seus direitos como Titular de dados</p>

                        <p>Por lei, pode solicitar-nos que informação recolhemos sobre si, e pode solicitar-nos para corrigi-los se forem imprecisos. Nós pedimos o seu consentimento para tratamento dos seus dados pessoais, poderá retirar esse consentimento a qualquer momento.</p>

                        <p>Poderemos fazer o tratamento dos seus dados pessoais porque nos deu o consentimento ou para cumprir um contrato, poderá solicitar-nos uma cópia da informação em formato digital para que possa transferi-lo para outro fornecedor.</p>

                        <p>Poderemos fazer o tratamento dos seus dados pessoais porque nos deu o consentimento ou interesse legítimo, poderá solicitar que os seus dados sejam apagados.</p>

                        <p>Tem o direito de nos solicitar que suspendamos o tratamento dos seus dados pessoais por um período de tempo se acreditar que não estamos a fazê-lo de forma licita.</p>

                        <p>Finalmente, em algumas circunstâncias, pode solicitar-nos para não fazermos processamento automatizado ou criação de perfil.</p>

                        <p>Para submeter um pedido sobre os seus dados pessoais poderá fazê-lo por e-mail, correio ou telefone, veja as informações de contacto fornecidas acima na parte “Quem somos”</p>

                        <p>Direito de reclamação</p>

                        <p>Se tem uma reclamação sobre o nosso tratamento dos seus dados pessoais, preferimos que entre em contato connosco diretamente em primeiro lugar para que possamos resolver a sua queixa. No entanto, é também possível contactar a Comissão Nacional de Proteção de Dados através do seu site em https://www.cnpd.pt/ ou para a morada:</p>

                        <p>
                            <br>
                        </p>

                        <p>Comissão Nacional de Protecção de Dados</p>

                        <p>Rua de São Bento n.º 148-3º</p>

                        <p>1200-821 Lisboa</p>

                        <p>Atualizações a esta política de privacidade</p>

                        <p>Iremos rever regularmente esta política e, se for caso disso, atualizar esta política de privacidade, uma vez que os nossos serviços e tratamento de dados pessoais evoluem. Se pretendermos utilizar os seus dados pessoais duma forma que não tenhamos previamente identificado, entraremos em contato consigo para fornecer informações sobre essa utilização e, se necessário, pedir o seu consentimento.</p>

                        <p>Atualizaremos o número de versão e a data deste documento sempre que for alterado.</p>

                        <p>
                            <br>
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'privacy-policy',
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll: function () {
                if ( window.scrollY > 10 ) {
                    document.getElementById('header').classList.add('scrolled')
                } else {
                    document.getElementById('header').classList.remove('scrolled')
                }
            }
        }
    }
</script>

<style lang="scss">
</style>