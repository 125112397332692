<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8 my-5 py-5">
                <div class="pt-5 mt-5">
                    <!-- <router-link to="/" class="back-arrow" aria-label="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort">
                        <div class="arrow"></div>
                        <span>{{$t('misc.back')}}</span>
                    </router-link> -->
                    <div class="title mb-4 text-center" v-html="$t('not_found.title')"></div>
                    <div class="txt text-center" v-html="$t('not_found.body')"></div>
                    <div class="my-5 d-flex justify-content-center">
                        <router-link to="/"  class="btn btn--primary">{{$t('misc.back')}}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'privacy-policy',
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll: function () {
                if ( window.scrollY > 10 ) {
                    document.getElementById('header').classList.add('scrolled')
                } else {
                    document.getElementById('header').classList.remove('scrolled')
                }
            }
        }
    }
</script>

<style lang="scss">
</style>