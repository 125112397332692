<template>
	<div id="animated_mouse" class="mouse-holder show d-none d-lg-flex">
        <div class="mouse">
            <div class="wheel"></div>
            <div class="arrow"></div>
        </div>
        <span>Scroll</span>
    </div>
</template>

<script>
export default {
    name: 'AnimatedMouse'
};
</script>
<style lang="scss">
    @keyframes scrollAnim {
        0% { 
            transform: translate3d(0,0,0);
            opacity: 1;
        };
        50% {
            opacity: 1;
        };
        100% {
            transform: translate3d(0,5px,0);
            opacity: 0;
        }
    }
    .mouse-holder {
        position: fixed;
        bottom: 70px;
        left: 100px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        z-index: 10;
        
        transition: opacity .2s ease-out, visibility .2s ease-out .2s;
        pointer-events: none;

        opacity: 0;
        visibility: hidden;
        &.show {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
        }
        .mouse {
            position: relative;
            width: 19px;
            min-width: 18px;
            height: 27px;
            border-radius: 10px;
            border: 1px solid #001A31;
        }
        .wheel {
            position: absolute;
            top: 5px;
            left: 6px;
            width: 4px;
            height: 5px;
            border-radius: 2px;
            border: 1px solid #001A31;
            animation: scrollAnim 1.5s infinite;
        }
        .arrow {
            position: absolute;
            top: 29px;
            left: 5px;
            width: 5px;
            height: 5px;
            border-right: 1px solid #001A31;
            border-bottom: 1px solid #001A31;
            transform: rotate(45deg);
        }
        span {
            margin-left: 14px;
            font-size: 12px;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
    }
</style>