<template>
	<div class='locale-changer'>
		<select ref="langoption" v-model="$root.$i18n.locale" @change="onChange($event)" aria-label="Language">
			<option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang.toUpperCase() }}</option>
		</select>
	</div>
</template>

<script>
export default {
	name: 'locale-changer',
	data() { 
		// console.log('this.$root.$i18n.locale',this.$root.$i18n.locale)
		return { 
			langs: ['pt', 'en'],
			ls_lang: localStorage.getItem('language')
		};
	},
	methods: {
		onChange() {
			localStorage.setItem('language', this.$root.$i18n.locale);
		}
	}
};
</script>

<style lang='scss'>
	select {
		border: none;
		background: transparent;
		-webkit-appearance: none;
		padding: 5px;
		outline: none;
		outline-offset: unset;
		min-width: 40px;
		cursor: pointer;
	}
	.locale-changer {
		display: flex;
		align-items: center;
		font-size: 12px;
	}
</style>