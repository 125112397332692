import Vue from 'vue'
import VueLocalStorage from 'vue-localstorage'
import Languages from '../../locales/translation'

Vue.use(VueLocalStorage)
const supportedLanguages = Object.getOwnPropertyNames(Languages)

export default {
	namespaced: true,
	state: {
		language: Vue.localStorage.get('language'),
	},
	mutations: {
		SET_LANGUAGE(state, lang) {
			// console.log(Vue.localStorage)
			
			Vue.localStorage.set('language', lang)
			state.language = lang
			// console.log(state.language) 
			this.$root.$i18n.locale = lang
			// console.log(this.$root.$i18n.locale)
		},
	},
	actions: {
		setLanguage({ commit }, languages) {
			if (typeof languages === 'string') {
				commit('SET_LANGUAGE', languages)
			} else {
				const language = supportedLanguages.find(sl =>
				languages.find(l => (l.split(new RegExp(sl, 'gi')).length - 1 > 0 ? sl : null)))
				commit('SET_LANGUAGE', language)
			}
		},
	},
}