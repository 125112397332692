<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8 my-5 py-5">
                <div class="pt-5">
                    <router-link to="/" class="back-arrow" aria-label="INPAR Restaurant - Aroeira Lisbon Hotel Sea & Golf Resort">
                        <div class="arrow"></div>
                        <span>{{$t('misc.back')}}</span>
                    </router-link>
                    <div class="title mb-4" v-html="$t('terms_and_conditions.title')"></div>
                    <div class="txt" v-if="$t('privacy_policy.lang') == 'pt'">
                        <p align="center">
                            <br>
                        </p>

                        <p>
                            <br>
                        </p>

                        <p align="center"><strong>InPar Restaurante – Politica de Reservas</strong></p>

                        <p>
                            <br>
                        </p>

                        <p>Pré-reserva</p>

                        <p>Os serviços de Pré-Reserva são meramente informativos, e têm como único e exclusivo propósito oferecer ao cliente a possibilidade de ser informado o mais rapidamente possível se é possível efetuar uma reserva individual (menos de 15 pessoas) para o dia e serviço solicitado. Para confirmação, o cliente receberá um email 72H com antecedência à data escolhida, informando definitivamente se a sua reserva foi efetuada ou, em caso de indisponibilidade, propondo outras datas disponíveis.</p>

                        <p>
                            <br>
                        </p>

                        <p>Reserva (incluindo reservas online)</p>

                        <p>
                            <br>
                        </p>

                        <p>Os serviços de reserva (e reserva on-line) destinam-se a reservar um serviço no Restaurante InPar. A utilização destes serviços implicará na plena aceitação desta Política de Reservas. Qualquer pedido especial (pratos especiais, localização de mesa, etc.) deve ser comunicado explicitamente e por escrito ao Restaurante e será válido após confirmação expressa do mesmo pelo departamento de reservas do Restaurante, podendo incorrer em custos adicionais.</p>

                        <p>
                            <br>
                        </p>

                        <p>O cancelamento da reserva pelo usuário não acarretará em custos de cancelamento antecipado. Após o cancelamento, e caso seja efetuado um pré-pagamento por serviços específicos (Jantares Temáticos, Pratos Especiais Encomendados, Bolos de Aniversário, etc.), o Restaurante InPar cobrará 100% desses serviços, ou seja, não cobrará proceder ao reembolso do valor já pago.</p>

                        <p>
                            <br>
                        </p>

                        <p>Requerimentos especiais:</p>

                        <p>
                            <br>
                        </p>

                        <p>Localização de Mesas: O Restaurante InPar não garante a localização da mesa no momento da reserva. A todo o momento, serão anotados pedidos especiais quanto à localização da mesa para cada reserva, sem poder garanti-los no imediato.</p>

                        <p>
                            <br>
                        </p>

                        <p>Dietas especiais: o Restaurante InPar oferece pratos adaptados para dietas especiais como celíacos, vegans ou vegetarianos. Se tem alguma alergia ou dieta especial, é imprescindível que nos informe no momento da reserva para que possamos informar o Chef.</p>

                        <p>
                            <br>
                        </p>

                        <p>Pratos ou bebidas especiais: no caso de solicitar um prato especial, será solicitado um pré-pagamento de 25 € por pessoa como garantia. Neste caso, o departamento de reservas informará os possíveis métodos de pagamento, sendo esse valor deduzido da fatura final do dia da reserva, a título de pré-pagamento.</p>

                        <p>
                            <br>
                        </p>

                        <p>Cancelamento.</p>

                        <p>
                            <br>
                        </p>

                        <p>O cliente pode, a qualquer momento, cancelar ou modificar sua reserva entrando em contato diretamente com o Restaurante. Caso o cliente não compareça sem aviso prévio, cancelaremos a reserva após a margem estipulada. A margem de tolerância estipulada para atrasos de reserva é de 30 minutos.</p>

                        <p>
                            <br>
                        </p>

                        <p>Os preços indicados no momento da reserva (Menu do Restaurante) incluem IVA (ou imposto equivalente) de acordo com a taxa de imposto aplicável no momento da reserva. Caso a taxa do imposto varie entre a data da reserva e a data do serviço gerando disparidade, nos termos da lei, a taxa de IVA ou imposto a incidir sobre o preço final, será a que corresponder no momento da reserva entrega dos serviços (ou acumulação de imposto), mesmo nos casos em que isso acarrete um acréscimo em relação ao preço final indicado ao cliente no momento da reserva.</p>

                        <p>
                            <br>
                        </p>

                        <p>O Restaurante InPar reserva-se o direito de modificar estes Termos e / ou Condições Gerais do Contrato, informando os usuários sobre as modificações feitas através de <a href="https://inpar.aroeiralisbonhotel.com/">https://inpar.aroeiralisbonhotel.com/</a></p>

                        <p>
                            <br>
                        </p>

                        <p>O cliente compromete-se a utilizar os serviços de acordo com a lei, os bons costumes e a ordem pública, bem como com o disposto nas presentes Condições Gerais e / ou Condições de Contratação. Consequentemente, é obrigatório a não utilização dos serviços, para fins ou efeitos ilícitos e / ou contrários às disposições destes Termos Gerais e / ou Condições de contrato, prejudiciais aos direitos e / ou interesses de terceiros ou que, em qualquer forma, possa prejudicar os serviços do Restaurante InPar e / ou a sua imagem.</p>

                        <p>
                            <br>
                        </p>

                        <p>O Restaurante InPar poderá, para maior agilidade e em benefício dos utilizadores, modificar unilateralmente, a qualquer momento e sem aviso prévio, os serviços prestados ou as condições operacionais, técnicas e de utilização dos serviços.</p>

                        <p>
                            <br>
                        </p>

                        <p>No caso de o cliente efetuar reservas em sites exteriores ao Restaurante InPar, a confirmação da reserva chegará ao utilizador através do referido intermediário, devendo o cliente cumprir as Políticas deste último. Se houver algum problema com a reserva, o Restaurante InPar entrará em contacto imediatamente com o intermediário para o informar, bem como com o cliente final, se possível. O Restaurante InPar não pode garantir a disponibilidade imediata através de intermediários e exclui-se de qualquer responsabilidade na gestão de reservas e / ou confirmações erradas por intermediários.</p>

                        <p>
                            <br>
                        </p>

                        <p>No caso de reservas com mais de 10 pessoas, o Restaurante confirmará cada reserva por telefone no mesmo dia, em hora útil. No caso de não conseguir comunicar com o número indicado, é imprescindível que o cliente confirme a reserva até às 13h00 ou 17H. Caso contrário, a reserva será cancelada automaticamente.</p>

                        <p>
                            <br>
                        </p>

                        <p>
                            <br>
                        </p>

                        <p>
                            <br>
                        </p>

                        <p>
                            <br>
                        </p>

                        <p>
                            <br>
                        </p>


                    </div>
                    <div class="txt" v-else>
                        <p align="center">
                            <br>
                        </p>

                        <p>
                            <br>
                        </p>
                        
                        <p align="center"><strong>InPar Restaurant – Reservation Policy</strong></p>

                        <p>
                            <br>
                        </p>

                        <p>Pre-booking</p>

                        <p>Pre-booking services are for information purposes only, and their sole and exclusive purpose is to offer the customer the possibility of being informed as quickly as possible if it is possible to make an individual reservation (less than 15 people) for the day and service requested. For confirmation, the customer will receive an email 72H in advance of the chosen date, definitely informing if their reservation was made or, in case of unavailability, proposing other available dates.</p>

                        <p>
                            <br>
                        </p>

                        <p>Booking (including online booking)</p>

                        <p>
                            <br>
                        </p>

                        <p>Reservation services (and online reservation) are intended for booking a service at the InPar Restaurant. The use of these services will imply the full acceptance of this Reservation Policy. Any special request (special dishes, table location, etc.) must be communicated explicitly and in writing to the Restaurant and will be valid after express confirmation by the Restaurant's reservation department and may incur additional costs.</p>

                        <p>
                            <br>
                        </p>

                        <p>The cancellation of the reservation by the user will not incur early cancellation costs. After cancellation, and if a prepayment is made for specific services (Themed Dinners, Special Ordered Dishes, Birthday Cakes, etc.), InPar Restaurant will charge 100% of these services, ie, it will not charge for the refund of the amount Already paid.</p>

                        <p>
                            <br>
                        </p>

                        <p>Special requirements:</p>

                        <p>Table Location: InPar Restaurant does not guarantee the location of the table at the time of booking. At all times, special requests will be noted regarding the location of the table for each reservation, without being able to guarantee them immediately.</p>

                        <p>
                            <br>
                        </p>

                        <p>Special Diets: InPar Restaurant offers dishes adapted to special diets such as celiac, vegan or vegetarian. If you have any allergies or special diet, it is essential that you inform us at the time of booking so that we can inform the Chef.</p>

                        <p>
                            <br>
                        </p>

                        <p>Special dishes or drinks: if you request a special dish, a prepayment of €25 per person will be requested as a guarantee. In this case, the reservations department will inform the possible methods of payment, this amount being deducted from the final invoice on the day of booking, as a prepayment.</p>

                        <p>
                            <br>
                        </p>

                        <p>Cancellation.</p>

                        <p>
                            <br>
                        </p>

                        <p>The customer can, at any time, cancel or modify his reservation by contacting the Restaurant directly. If the customer does not show up without prior notice, we will cancel the reservation after the stipulated margin. The stipulated margin of tolerance for reservation delays is 30 minutes.</p>

                        <p>
                            <br>
                        </p>

                        <p>The prices stated at the time of booking (Restaurant Menu) include VAT (or equivalent tax) according to the tax rate applicable at the time of booking. If the tax rate varies between the booking date and the service date, generating a disparity, under the law, the VAT or tax rate to be levied on the final price, it will be the one that corresponds at the time of booking, delivery of the services (or accumulation of tax), even in cases where this entails an increase in relation to the final price indicated to the customer at the time of booking.</p>

                        <p>
                            <br>
                        </p>

                        <p>InPar Restaurant reserves the right to modify these General Terms and/or Conditions of Contract, informing users of the modifications made through <a href="https://inpar.aroeiralisbonhotel.com/">https://inpar.aroeiralisbonhotel.com/</a></p>

                        <p>The customer undertakes to use the services in accordance with the law, good customs and public order, as well as the provisions of these General Conditions and/or Contracting Conditions. Consequently, it is mandatory not to use the services, for illegal purposes or effects and / or contrary to the provisions of these General Terms and / or Conditions of contract, harmful to the rights and / or interests of third parties or that, in any way, may harm the InPar Restaurant services and/or its image.</p>

                        <p>
                            <br>
                        </p>

                        <p>InPar Restaurant may, for greater flexibility and for the benefit of users, unilaterally modify, at any time and without prior notice, the services provided or the operational, technical and use conditions of the services.</p>

                        <p>
                            <br>
                        </p>

                        <p>If the customer makes reservations on sites outside the InPar Restaurant, the booking confirmation will reach the user through the said intermediary, and the customer must comply with the policies of the latter. If there is any problem with the reservation, InPar Restaurant will contact the intermediary immediately to inform you, as well as the final customer, if possible. InPar Restaurant cannot guarantee immediate availability through intermediaries and excludes itself from any responsibility for managing reservations and/or wrong confirmations by intermediaries.</p>

                        <p>
                            <br>
                        </p>

                        <p>In the case of reservations with more than 10 people, the Restaurant will confirm each reservation by telephone on the same day, at a convenient time. In case you are unable to communicate with the indicated number, it is essential that the client confirms the reservation by 13:00 or 17:00. Otherwise, the reservation will be cancelled automatically.</p>

                        <p>
                            <br>
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'terms-and-conditions',
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll: function () {
                if ( window.scrollY > 10 ) {
                    document.getElementById('header').classList.add('scrolled')
                } else {
                    document.getElementById('header').classList.remove('scrolled')
                }
            }
        }
    }
</script>

<style lang="scss">
</style>