
import './assets/css/theme_0_xs.scss'
import './assets/css/theme_768_md.scss'
import './assets/css/theme_992_lg.scss'
import './assets/css/theme_1200_xl.scss'
import './assets/css/theme_1540_xxl.scss'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import { BootstrapVue } from 'bootstrap-vue'
import VueFullPage from 'vue-fullpage.js'
import i18n from './i18n'


// Vue.use(BootstrapVue)
Vue.use(VueFullPage)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	i18n,
	el: '#app',
	render: h => h(App)
}).$mount('#app')